import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { EditSenderRequestModel } from './EditSenderRequestModel';
import { AddHistoryRequestModel } from './SenderRequestApprovalHistory/AddHistoryRequestModel';

@Service()
export default class SenderRequestSupportService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async get(senderName: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-request-support-page/get-sender-support-details';
            const result = await this.axiosService.axiosInstance.get(url, {
                params: { senderName }
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getSenderRequestApprovalHistory(
        id: string, pageIndex: string,
        pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName +
                'api/sender-request-support-page/get-sender-request-approval-history?id=' + id + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async editSenderRequestStatus(model: EditSenderRequestModel): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests/edit-request';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async addRequestHistory(model: AddHistoryRequestModel): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests/add-history';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getPaymentTransactionByRequestid(
        id: string, pageIndex: string,
        pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-request-support-page/get-payment-transaction-by-requestid?id=' + id
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async deleteRequestHistory(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests/delete-history';
            const result = await this.axiosService.axiosInstance.delete(url, {
                params: {
                    id,
                },
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}