import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './Report.html';
import ReportService from './ReportService';
import { DropdownModel, ReportModel } from './ReportModel';
import { Toaster } from '../../Services/toast';
import { SenderType } from '../../shared/Enums/SenderType';
import ProviderService from '../Provider/ProviderService';
import DashboardService from '../Dashboard/DashboardService';
import ValidationService from '../../Services/ValidationService';
import { RequestStatus } from '../../shared/Enums/RequestStatus';
import LoaderService from '../../Services/LoaderService';
import { ReportDataModel } from './ReportDataModel';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        ReportService,
        ProviderService,
        DashboardService,
        ValidationService,
        LoaderService,
    ],
})
export default class Report extends Vue {
    @Inject(ReportService) public reportService!: ReportService;
    @Inject(ProviderService) public providerService!: ProviderService;
    @Inject(DashboardService) public dashboardService!: DashboardService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public approveDateFromMenu: boolean = false;
    public approveDateToMenu: boolean = false;
    public createdDateToMenu: boolean = false;
    public createdDateFromMenu: boolean = false;
    public providerOptions: any[] = [];
    public senderIdList: any[] = [];
    public reportModel: ReportDataModel[] = [];
    public Count: number = 0;
    public model: ReportModel = {
        requestId: '',
        senderName: '',
        tenantName: '',
        approveDateFrom: '',
        approveDateTo: '',
        createdDateFrom: '',
        createdDateTo: '',
        customerType: 0,
        senderType: 0,
        providerId: '',
        comment: '',
        clientName: '',
        representativeName: '',
        representativeMobile: '',
        requestStatus: 0,
        senderStatus: 0,
        paymentDate: '',
        expiryDate: '',
        numberOfActions: 0,
        filterOption: 0,
        filterExpiryOption: 0
    };
    public customerTypeList: DropdownModel[] = [
        {
            id: 1,
            name: this.$t('shared.government').toString(),
        },
        {
            id: 2,
            name: this.$t('shared.charity').toString(),
        },
        {
            id: 3,
            name: this.$t('shared.private').toString(),
        },
        {
            id: 4,
            name: this.$t('shared.international').toString(),
        },
    ];
    public senderTypeList: DropdownModel[] = [
        {
            id: SenderType.Advertisement,
            name: this.$t('shared.advertisement').toString(),
        },
        {
            id: SenderType.Service,
            name: this.$t('shared.service').toString(),
        },
        {
            id: SenderType.Awareness,
            name: this.$t('shared.awareness').toString(),
        },
        {
            id: SenderType.Warning,
            name: this.$t('shared.warning').toString(),
        },
    ];
    public requestStatusist: DropdownModel[] = [
        {
            id: RequestStatus.PendingApproval,
            name: this.$t('shared.PendingApproval').toString(),
        },
        {
            id: RequestStatus.Approved,
            name: this.$t('shared.Approved').toString(),
        },
        {
            id: RequestStatus.Rejected,
            name: this.$t('shared.Rejected').toString(),
        },
        {
            id: RequestStatus.Paid,
            name: this.$t('shared.Paid').toString(),
        },
        {
            id: RequestStatus.Expired,
            name: this.$t('shared.Expired').toString(),
        },
        {
            id: RequestStatus.Deleted,
            name: this.$t('shared.Deleted').toString(),
        },
    ];
    public headers: any = [
        { text: this.$t('dashboardPage.requestId'), value: 'requestId', align: 'center', sortable: false },
        { text: this.$t('shared.senderName'), value: 'senderName', align: 'center', sortable: false },
        { text: this.$t('shared.tenantName'), value: 'tenantName', align: 'center', sortable: false },
        { text: this.$t('shared.clientName'), value: 'clientName', align: 'center', sortable: false },
        { text: this.$t('shared.customerType'), value: 'customerType', align: 'center', sortable: false },
        { text: this.$t('shared.senderType'), value: 'senderType', align: 'center', sortable: false },
        { text: this.$t('shared.requestStatus'), value: 'requestStatus', align: 'center', sortable: false },
        { text: this.$t('shared.crNumber'), value: 'crNumber', align: 'center', sortable: false },
        { text: this.$t('shared.rejectionReasonOrComment'), value: 'rejectionResson', align: 'center', sortable: false },
        { text: this.$t('shared.providerName'), value: 'providerName', align: 'center', sortable: false },
        { text: this.$t('shared.representativeName'), value: 'representativeName', align: 'center', sortable: false },
        { text: this.$t('shared.representativeEmail'), value: 'representativeEmail', align: 'center', sortable: false },
        {
            text: this.$t('dashboardPage.representativeMobile'), value: 'representativeMobile',
            align: 'center', sortable: false,
        },
        { text: this.$t('shared.numberOfActions'), value: 'numberOfActions', align: 'center', sortable: false },
        { text: this.$t('shared.createdDate'), value: 'createdDate', align: 'center', sortable: false },
        { text: this.$t('shared.paymentDate'), value: 'paymentDate', align: 'center', sortable: false },
        { text: this.$t('shared.expiryDate'), value: 'expiryDate', align: 'center', sortable: false },
        { text: this.$t('shared.lastModifiedDate'), value: 'lastModifiedDate', align: 'center', sortable: false },
        //   { text: this.$t('shared.zainActivationDate'), value: 'zainActivationDate', align: 'center', sortable: false },
        // { text: this.$t('shared.sTCActivationDate'), value: 'sTCActivationDate', align: 'center', sortable: false },
        //{ text: this.$t('shared.mobilyActivationDate'), value: 'mobilyActivationDate', align: 'center', sortable: false },
    ];
    public created() {
        this.$emit('childinit', this.layoutSup);
    }
    public async mounted() {
        const response = await this.providerService.Get('', '', '', '', '0', '10000');
        this.providerOptions = response.result;
    }
    public async exportToExcel() {
        this.loaderService.ShowLoader();
        const response = await this.reportService.GetSenderNameAndBeneficiariesReport
            (this.model, '0', '-1', true);
        if (!response.isSuccess) {
            this.loaderService.HideLoader();
            Toaster.error(response.errorMessage);
            this.reportModel = [];
            this.Count = 0;
        } else {
            this.loaderService.HideLoader();
        }
    }
    public async search() {
        this.loaderService.ShowLoader();
        const response = await this.reportService.GetSenderNameAndBeneficiariesReport
            (this.model, '0', '10', false);
        if (!response.isSuccess) {
            this.loaderService.HideLoader();
            Toaster.error(response.errorMessage);
            this.reportModel = [];
            this.Count = 0;
        } else {
            this.loaderService.HideLoader();
            this.reportModel = response.result;
            this.Count = response.totalCount;
        }
    }
    public async updatePaginate(val: any) {
        const response = await this.reportService.GetSenderNameAndBeneficiariesReport(this.model,
            (val.page - 1).toString(), val.rowsPerPage, false);
        if (!response.isSuccess) {
            this.loaderService.HideLoader();
            Toaster.error(response.errorMessage);
            this.reportModel = [];
            this.Count = 0;
        } else {
            this.loaderService.HideLoader();
            this.reportModel = response.result;
            this.Count = response.totalCount;
        }
    }
    public reset() {
        this.model = {
            requestId: '',
            senderName: '',
            tenantName: '',
            approveDateFrom: '',
            approveDateTo: '',
            createdDateFrom: '',
            createdDateTo: '',
            customerType: 0,
            senderType: 0,
            providerId: '',
            clientName: '',
            requestStatus: 0,
            senderStatus: 0,
            representativeName: '',
            comment: '',
            representativeMobile: '',
            paymentDate: '',
            expiryDate: '',
            numberOfActions: 0,
            filterOption: 0,
            filterExpiryOption: 0
        };
        this.search();
    }
    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }

    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
}
