import Progress from 'easy-circular-progress';
import { Inject } from 'vue-di-container';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import LoaderService from '../../Services/LoaderService';
import { Toaster } from '../../Services/toast';
import ValidationService from '../../Services/ValidationService';
import UserModel from '../../shared/userService/Model/UserModel';
import UserRoleService from '../../shared/userService/UserRoleService';
import UserService from '../../shared/userService/UserService';
import { ReportModel } from '../Report/ReportModel';
import ReportService from '../Report/ReportService';
import WithRender from './ConnectingRequestDashboard.html';
import { ContractDashboardBoxesModel } from './ContractDashboardBoxesModel';
import ConnectingRequestDashboardService from './ConnectingRequestDashboardService';
import { FilterModel } from './FilterModel';
import { ContractSenderRequestModel } from './ContractSenderRequestModel';
import { ContractOptionsModel } from './ContractOptionsModel ';
import { RejectionReasonModel } from '../RejectionReason/RejectionReasonModel';
import ProviderStatusService from '../../shared/ProviderStatusService/ProviderStatusService';


@WithRender
@Component({
    components: {
        Progress,
    },
    diProvide: [
        ConnectingRequestDashboardService,
        UserService,
        ReportService,
        UserRoleService,
        ProviderStatusService
    ],
})
export default class ConnectingRequestDashboard extends Vue {
    @Inject(UserService) public userService!: UserService;
    @Inject(ConnectingRequestDashboardService) public connectingRequestDashboardService!: ConnectingRequestDashboardService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(ReportService) public reportService!: ReportService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Inject(ProviderStatusService) public providerStatusService!: ProviderStatusService;
    @Prop({ type: Boolean, default: false })
    public providerLocked: boolean = true;
    public providerDissconected: boolean = true;
    public providerExpired: boolean = true;
    public layoutSup: boolean | any;
    public requestStatus: string = '';
    public senderStatus: string = '';
    public Deleteids: string[] = [];
    public deleteDialog: boolean = false;
    public approveids: string[] = [];
    public approveDialog: boolean = false;
    public resendids: string[] = [];
    public resendDialog: boolean = false;
    public resendComment: string = '';
    public contractFilterOption: number = 0;
    public contractFilterExpiryOption: number = 0;
    public approveComment: string = '';
    public deleteComment: string = '';
    public contractDateRequestOptions: any = 
        [
            { "id": 0, "name": "All" },
            { "id": 1, "name": "Oldest" },
            { "id": 2, "name": "Newest" },
        ];
    public contractDateRequestExpiryOptions: any =
        [
            { "id": 1, "name": "90 Days" },
            { "id": 2, "name": "30 Days" },
            { "id": 3, "name": "7 Days" },
        ];
    public popUpRejectionReasons: RejectionReasonModel[] = [];
    public selectedRejectReason: RejectionReasonModel = {} as RejectionReasonModel;
    public isItOtherReasonPorp: boolean = false;
    public filterModel: FilterModel = {
        senderName: '',
        clientName: '',
        createdDate: '',
        providerName: '',
        requestId: '',
        status: 0,
    };
    public reportModel: ReportModel = {
        requestId: '',
        senderName: '',
        tenantName: '',
        approveDateFrom: '',
        approveDateTo: '',
        createdDateFrom: '',
        createdDateTo: '',
        customerType: 0,
        senderType: 0,
        providerId: '',
        clientName: '',
        comment: '',
        representativeName: '',
        representativeMobile: '',
        requestStatus: 0,
        senderStatus: 0,
        numberOfActions: 0,
        paymentDate: '',
        expiryDate: '',
        filterOption: 0,
        filterExpiryOption:0
    };
    public statusOptions: any[] = [
        {
            id: '1',
            name: this.$t('dashboardPage.pendingFor').toString()
                + ' ' + this.$t('dashboardPage.approval').toString(),
        },
        {
            id: '2',
            name: this.$t('dashboardPage.pendingFor').toString()
                + ' ' + this.$t('dashboardPage.payment').toString(),
        },
        {
            id: '3',
            name: this.$t('dashboardPage.rejected').toString()
                + ' ' + this.$t('dashboardPage.requests').toString(),
        },
        {
            id: '4',
            name: this.$t('dashboardPage.inactive').toString()
                + ' ' + this.$t('dashboardPage.requests').toString(),
        },
        {
            id: '5',
            name: this.$t('dashboardPage.active').toString()
                + ' ' + this.$t('dashboardPage.requests').toString(),
        },
        {
            id: '7',
            name: this.$t('dashboardPage.partially').toString()
                + ' ' + this.$t('dashboardPage.activated').toString(),
        },
    ];
    public user: UserModel = {
        userId: '',
        username: '',
        details: {
            Email: '',
            MobilePhone: '',
            Operator: '',
            Provider: '',
            OperatorId: '',
            ProviderId: '',
            TenantId: '',
            Role: '',
            ChangePasswordRequired: '',
        },
    };
    public autoInterval: any;
    public userRole: any;
    public scrollDiv: any;
    public nextPage: string = '';
    public scrollTop: number = 0;
    public selected: ContractSenderRequestModel[] = [];
    public selectedIds: string[] = [];
    public ids: string[] = [];
    public senders: ContractSenderRequestModel[] = [];
    public sendersCount: number = 0;
    public dashboardBoxes: ContractDashboardBoxesModel = {} as ContractDashboardBoxesModel;
    public contractOptions: ContractOptionsModel = {} as ContractOptionsModel;
    public User: any;
    public rejectResone: string = '';
    public rejectdialog: boolean = false;
    public anySelected: boolean = false;
    public pagination: any = {};
    public tenantId: string = '';
    public headers: any = [
        { text: this.$t('dashboardPage.requestId'), sortable: false, value: 'requestId', align: 'center' },
        { text: this.$t('dashboardPage.senderName'), sortable: true, value: 'senderName', align: 'center' },
        { text: this.$t('dashboardPage.clientName'), sortable: true, value: 'clientName', align: 'center' },
        {
            text: this.$t('dashboardPage.providerName'), sortable: true,
            value: 'providerName', align: 'center',
        },
        {
            text: this.$t('dashboardPage.requestDate'), sortable: true,
            value: 'createdDate', align: 'center',
        },
        {
            text: this.$t('dashboardPage.expiryDate'), sortable: true,
            value: 'expiryDate', align: 'center',
        },
        { text: this.$t('dashboardPage.operatorStatus'), sortable: false, align: 'center' },
        {
            text: this.$t('dashboardPage.status'), sortable: false, value: 'requestStatus',
            align: 'center', width: '190',
        },
        {
            text: this.$t('dashboardPage.senderExpiryDate'), sortable: false, value: 'senderexpiryDate', align: 'center'
        },
        { text: this.$t('shared.actions'), sortable: false, align: 'center', width: '145' },
    ];
    public changeSort(column: any) {
        if (this.pagination.sortBy === column) {
            this.pagination.descending = !this.pagination.descending;
        } else {
            this.pagination.sortBy = column;
            this.pagination.descending = false;
        }
        this.updatePaginate(this.pagination);
    }
    public checkColor(typeStatus: string) {
        typeStatus = typeStatus.toString();
        switch (typeStatus) {
            case '1': {
                return '#c427ba';
                break;
            }
            case '2': {
                return '#5aaa0b';
                break;
            }
            case '3': {
                return '#C42727';
                break;
            }
            case '4': {
                return '#aaa7a7';
                break;
            }
            case '5': {
                return '#014bb1';
                break;
            }
            case '7': {
                return '#5c9bf2';
                break;
            }
            case '10': {
                return '#9b2a5a';
                break;
            }
            default: {
                return '#F78500';
                break;
            }
        }
    }
    public GetSenderStatus(typeStatus: string) {
        typeStatus = typeStatus.toString();
        switch (typeStatus) {
            case '1': {
                return 'Pending Approval';
                break;
            }
            case '2': {
                return 'Approved';
                break;
            }
            case '3': {
                return 'Rejected';
                break;
            }
            case '4': {
                return 'Paid';
                break;
            }
            case '5': {
                return 'Expired';
                break;
            }
            case '6': {
                return 'Deleted';
                break;
            }
            case '7': {
                return 'Partially Activated';
                break;
            }
            case '10': {
                return 'Pending For Review';
                break;
            }
            default: {
                return '';
                break;
            }
        }
    }
    
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.User = await this.userService.getUser();
        this.userRole = this.userRoleService.GetUserRole(this.User);
        if (this.userRole === 2 || this.userRole === 5) {
            this.isProviderLocked();
            this.isProviderExpired();
            this.isProviderDissconected();
        }
        if (this.userRole === 1 || this.userRole === 9 || this.userRole === 15) {
            this.getRejectionReasons();
        }
        this.dashboardBoxes = await this.connectingRequestDashboardService.GetDashboardBoxes();
        this.tenantId = this.User.details.TenantId;
        this.getContractOptions();
        this.search();
    }
    public async getContractOptions() {
        const response = await this.connectingRequestDashboardService.getContractOptions();
        this.contractOptions.isNewContractEnabled = response.result.isNewContractEnabled;
        this.contractOptions.isRenewContractEnabled = response.result.isRenewContractEnabled;
    }

    public async isProviderLocked() {
        const response = await this.connectingRequestDashboardService.isProviderLocked();
        this.providerLocked = response.result;
    }
    public async isProviderExpired() {
        const response = await this.connectingRequestDashboardService.isProviderExpired();
        this.providerExpired = response.result;
    }
    public async isProviderDissconected() {
        const response = await this.providerStatusService.isProviderDissconected();
        this.providerDissconected = response.result;
    }
    public async search() {
        let model;
        if (localStorage.storSettings) {
            const storSettings = JSON.parse(localStorage.storSettings);
            this.pagination = storSettings.pagination;
            model = storSettings.filter;
            this.filterModel.senderName = storSettings.filter.senderName;
            this.filterModel.requestId = storSettings.filter.requestId;
            this.filterModel.clientName = storSettings.filter.clientName;
            this.filterModel.providerName = storSettings.filter.providerName;
            this.filterModel.createdDate = storSettings.filter.createdDate;
            this.requestStatus = storSettings.filter.requestStatus;
            this.senderStatus = storSettings.filter.senderStatus;
        } else {
            model = {
                senderName: this.filterModel.senderName,
                requestId: this.filterModel.requestId,
                clientName: this.filterModel.clientName,
                providerName: this.filterModel.providerName,
                createdDate: this.filterModel.createdDate,
                requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
                senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
                sortDirection: false,
                contractFilterOption: this.contractFilterOption,
                contractFilterExpiryOption: this.contractFilterExpiryOption,
                sortBy: '',
                pageIndex: '0',
                pagesize: '10',
            };
        }
        localStorage.removeItem('storSettings');
        const response = await this.connectingRequestDashboardService.Get(model);
        if (response !== false) {
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            }
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }

    public isEqual(value1: string | null, value2: string) {
        return (value1 === '' || value1 === null || value2.includes(value1));
    }

    public async filter(requestStatus: string, senderStatus: string) {
        if (this.requestStatus === requestStatus &&
            this.senderStatus === senderStatus) {
            this.requestStatus = '0';
            this.senderStatus = '0';
            requestStatus = '0';
            senderStatus = '0';
        } else {
            this.requestStatus = requestStatus;
            this.senderStatus = senderStatus;
        }
        const model = {
            senderName: this.filterModel.senderName,
            requestId: this.filterModel.requestId,
            clientName: this.filterModel.clientName,
            providerName: this.filterModel.providerName,
            createdDate: this.filterModel.createdDate,
            requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
            senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
            sortDirection: this.pagination.descending,
            sortBy: this.pagination.sortBy,
            contractFilterOption: this.contractFilterOption,
            contractFilterExpiryOption: this.contractFilterExpiryOption,
            pageIndex: '0',
            pagesize: '10',
        };
        const response = await this.connectingRequestDashboardService.Get(model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }

    public selectAll() {
        if (this.selected.length) {
            this.selected = [];
            this.selectedIds = [];
        } else {
            this.selected = this.senders.filter(sender => {
                return (sender.requestStatus);
            }).slice();
            this.selectedIds = this.senders.filter(sender => {
                return (sender.requestStatus);
            }).map((x) => x.id);
        }
    }
    public select(event: any, id: string) {
        if (!event) {
            const index = this.selectedIds.indexOf(id);
            this.selectedIds.splice(index, 1);
        } else {
            this.selectedIds.push(id);
        }
    }
    public getRate(remainingDays: string, totalDays: string) {
        return (parseInt(remainingDays, 10) / parseInt(totalDays, 10)) * 100;
    }

    public showapproveDialog(id: string) {
        if (id !== '') {
            this.selectedIds.push(id);
        }
        if (this.senders.filter((s) => this.selectedIds.includes(s.id) && s.automaticallyInserted)
            .length > 0) {
            Toaster.error(this.$t('dashboardPage.someContractsAreMergeInserted').toString());
            return;
        }
        this.approveDialog = true;
    }
    public hideapproveDialog() {
        this.approveComment = '';
        this.approveDialog = false;
    }
    public async approve(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        } else {
            ids = this.selectedIds;
            if (this.senders.filter((s) => ids.includes(s.id) && s.requestStatus.toString() !== '1')
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderPendingApproval').toString());
                return;
            }
            if (this.senders.filter((s) => ids.includes(s.id) && s.tenantId !== this.tenantId)
                .length > 0 && this.userRole === 1) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderRequestRelatedToThisTenant').toString());
                return;
            }
            if (this.senders.filter((s) => ids.includes(s.id) && s.automaticallyInserted)
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.someContractsAreMergeInserted').toString());
                return;
            }
        }
        const response = await this.connectingRequestDashboardService.approve(ids, this.approveComment);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
            await this.reload();
            this.selectedIds = [];
        } else {
            Toaster.success(this.$t('dashboardPage.Approvedsuccessfully').toString());
            await this.connectingRequestDashboardService.contractApproveEmail(ids);
            await this.reload();
            this.selectedIds = [];
        }
        this.hideapproveDialog();
    }

    public showDeleteDialog(id: string) {
        this.Deleteids = [];
        if (id !== '') {
            this.Deleteids.push(id);
        } else {
            this.Deleteids = this.selectedIds;
        }
        this.deleteDialog = true;
    }
    public hideDeleteDialog() {
        this.deleteDialog = false;
    }
    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }
    public showresendDialog(id: string) {
        if (id !== '') {
            this.selectedIds.push(id);
        } else {
            this.resendids = this.selectedIds;
        }
        if (this.senders.filter((s) => this.selectedIds.includes(s.id) && s.automaticallyInserted)
            .length > 0) {
            Toaster.error(this.$t('dashboardPage.someContractsAreMergeInserted').toString());
            return;
        }
        this.resendDialog = true;
    }
    public async addRequest() {
        let isRenew: string = 'false';
        this.$router.push({ name: 'ConnectingSenderName', params: { isRenew } });

    }
    public async checkRenewContract(id: string) {
        if (this.isAnySelected) {
            let ids: string[] = [];
            if (id !== '') {
                ids.push(id);
            } else {
                ids = this.selectedIds;
            }
            const response = await this.connectingRequestDashboardService.checkRenewContract(ids);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
                await this.reload();
                this.selectedIds = [];
            } else {
                this.$router.push({ name: 'RenewContractRequest', params: { ids: ids.join(',') } });
            }
        }
        else {
            let isRenew: string = 'true';
            this.$router.push({ name: 'ConnectingSenderName', params: { isRenew } });
        }


    }

    public hideresendDialog() {
        this.resendComment = '';
        this.resendDialog = false;
    }
    public async exportToExcel() {
        this.loaderService.ShowLoader();
        this.reportModel.filterOption = this.contractFilterOption;
        this.reportModel.filterExpiryOption = this.contractFilterExpiryOption;
        this.reportModel.requestStatus = this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0;
        const response = await this.reportService.GetContractRequestsReport
            (this.reportModel, '0', '0', true);
        if (!response.isSuccess) {
            this.loaderService.HideLoader();
            Toaster.error(response.errorMessage);
        } else {
            this.loaderService.HideLoader();
        }
    }

    public async deleteSender() {
        let ids: string[] = [];
        ids = this.Deleteids;
        if (this.senders.filter((s) => ids.includes(s.id) && !(s.requestStatus.toString() === '4'
            || s.requestStatus.toString() === '2')).length > 0) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderApprovedOrPaid').toString());
            return;
        }
        const response = await this.connectingRequestDashboardService.delete(ids[0], this.deleteComment);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
            this.deleteDialog = false;
            await this.reload();
        } else {
            this.deleteDialog = false;
            this.selectedIds = [];
            this.connectingRequestDashboardService.deleteRequestEmail(this.Deleteids);
            Toaster.success(this.$t('shared.deleteSuccessfully').toString());
            await this.reload();
        }
    }
    public async resend(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        } else {
            ids = this.selectedIds;
            if (this.senders.filter((s) => ids.includes(s.id) && s.automaticallyInserted)
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.someContractsAreMergeInserted').toString());
                return;
            }
            if (this.senders.filter((s) => ids.includes(s.id) && s.requestStatus.toString() !== '3')
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderRejected').toString());
                return;
            }
        }
        const response = await this.connectingRequestDashboardService.resend(ids, this.resendComment);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        } else {
            await this.connectingRequestDashboardService.resendContractRequestEmail(ids);
            this.selectedIds = [];
            Toaster.success(this.$t('dashboardPage.resentSuccessfully').toString());
            await this.reload();
        }
        this.hideresendDialog();
    }
    public async showRejectDialog(id: string) {
        this.ids = [];
        if (id !== '') {
            this.ids.push(id);
        } else {
            this.ids = this.selectedIds;
            if (this.senders.filter((s) => this.ids.includes(s.id) && s.requestStatus.toString() !== '1')
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderPendingApproval').toString());
                return;
            }
            if (this.senders.filter((s) => this.ids.includes(s.id) && s.automaticallyInserted)
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.someContractsAreMergeInserted').toString());
                return;
            }
        }
        this.rejectdialog = true;
    }
    public async hideRejectDialog() {
        this.rejectResone = '';
        this.rejectdialog = false;
    }
    public async reject() {
        if (this.senders.filter((s) => this.ids.includes(s.id) && s.tenantId !== this.tenantId)
            .length > 0 && this.userRole === 1) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderRequestRelatedToThisTenant').toString());
            return;
        }
        if (this.rejectResone !== '') {
            this.rejectdialog = false;
            const response = await this.connectingRequestDashboardService.reject(this.ids, this.rejectResone);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            } else {
                await this.connectingRequestDashboardService.rejectContractRequestEmail(this.ids);
                this.rejectResone = '';
                this.selectedIds = [];
                this.ids = [];
                Toaster.success(this.$t('dashboardPage.Rejectedsuccessfully').toString());
                await this.reload();
            }
        }
    }
    public PreparePay(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        } else {
            ids = this.selectedIds;
            if (this.senders.filter((s) => ids.includes(s.id) && s.requestStatus.toString() !== '2')
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderPendingPay').toString());
                return;
            }
        }
        const storSettings = {
            pagination: this.pagination,
            filter: {
                senderName: this.filterModel.senderName,
                requestId: this.filterModel.requestId,
                clientName: this.filterModel.clientName,
                providerName: this.filterModel.providerName,
                createdDate: this.filterModel.createdDate,
                requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
                senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
                pageIndex: (this.pagination.page - 1),
                pageSize: this.pagination.rowsPerPage,
                sortDirection: this.pagination.descending,
                sortBy: this.pagination.sortBy,
            },
        };
        localStorage.storSettings = JSON.stringify(storSettings);
        this.$router.push({ name: 'Payment', params: { ids: ids.join(',') } });
    }
    public Details(id: string) {
        const storSettings = {
            pagination: this.pagination,
            filter: {
                senderName: this.filterModel.senderName,
                requestId: this.filterModel.requestId,
                clientName: this.filterModel.clientName,
                providerName: this.filterModel.providerName,
                createdDate: this.filterModel.createdDate,
                requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
                senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
                pageIndex: (this.pagination.page - 1),
                pageSize: this.pagination.rowsPerPage,
                sortDirection: this.pagination.descending,
                sortBy: this.pagination.sortBy,
            },
        };
        localStorage.storSettings = JSON.stringify(storSettings);
        this.$router.push({ name: 'ContractRequestInfo', params: { id } });
    }
    public ChangeSenderType(id: string) {
        const storSettings = {
            pagination: this.pagination,
            filter: {
                senderName: this.filterModel.senderName,
                requestId: this.filterModel.requestId,
                clientName: this.filterModel.clientName,
                providerName: this.filterModel.providerName,
                createdDate: this.filterModel.createdDate,
                requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
                senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
                pageIndex: (this.pagination.page - 1),
                pageSize: this.pagination.rowsPerPage,
                sortDirection: this.pagination.descending,
                sortBy: this.pagination.sortBy,
            },
        };
        localStorage.storSettings = JSON.stringify(storSettings);
        this.$router.push({ name: 'ChangeSenderType', params: { id } });
    }
    public UpdateRequestData(id: string) {
        const storSettings = {
            pagination: this.pagination,
            filter: {
                senderName: this.filterModel.senderName,
                requestId: this.filterModel.requestId,
                clientName: this.filterModel.clientName,
                providerName: this.filterModel.providerName,
                createdDate: this.filterModel.createdDate,
                requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
                senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
                pageIndex: (this.pagination.page - 1),
                pageSize: this.pagination.rowsPerPage,
                sortDirection: this.pagination.descending,
                sortBy: this.pagination.sortBy,
            },
        };
        localStorage.storSettings = JSON.stringify(storSettings);
        this.$router.push({ name: 'UpdateContractRequest', params: { id } });
    }
    public ConectivityDetail(senderId: string) {
        this.$router.push({ name: 'ContractConnectivityDetail', params: { senderId } });
    }
    public async updatePaginate(val: any) {
        const model = {
            senderName: this.filterModel.senderName,
            requestId: this.filterModel.requestId,
            clientName: this.filterModel.clientName,
            providerName: this.filterModel.providerName,
            createdDate: this.filterModel.createdDate,
            requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
            senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
            pageIndex: (val.page - 1).toString(),
            pagesize: val.rowsPerPage,
            contractFilterOption: this.contractFilterOption,
            contractFilterExpiryOption: this.contractFilterExpiryOption,
            sortDirection: this.pagination.descending,
            sortBy: this.pagination.sortBy,
        };
        const response = await this.connectingRequestDashboardService.Get(model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }
    public get isAnySelected(): boolean {
        this.anySelected = this.selectedIds.length > 0;
        return this.anySelected;
    }
    public async reload() {
        this.contractFilterOption = 0;
        this.contractFilterExpiryOption = 0;
        const model = {
            senderName: this.filterModel.senderName,
            requestId: this.filterModel.requestId,
            clientName: this.filterModel.clientName,
            providerName: this.filterModel.providerName,
            createdDate: this.filterModel.createdDate,
            requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
            senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
            pageIndex: '0',
            pagesize: '10',
            contractFilterOption: 0,
            contractFilterExpiryOption: 0,
            sortDirection: false,
            sortBy: '',
        };
        const response = await this.connectingRequestDashboardService.Get(model);
        this.dashboardBoxes = await this.connectingRequestDashboardService.GetDashboardBoxes();
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
        this.selectedIds = [];
        this.selected = [];
    }

    private internalUpdate = false;

    @Watch('contractFilterOption', { deep: true })
    public async onchangeContractFilter() {
        if (!this.internalUpdate) {
            this.internalUpdate = true;
            this.contractFilterExpiryOption = 0;
            await this.$nextTick();
            this.search();
            this.internalUpdate = false;
        }
    }

    @Watch('contractFilterExpiryOption', { deep: true })
    public async onchangeContractFilterExpiry() {
        if (!this.internalUpdate) {
            this.internalUpdate = true;
            this.contractFilterOption = 0;
            await this.$nextTick();
            this.search();
            this.internalUpdate = false;
        }
    }

    public get rejectReasonIsValid(): boolean {
        return this.rejectResone !== '';
    }

    public async getRejectionReasons() {
        const response = await this.connectingRequestDashboardService.getSenderNameRejectReasons(2);
        this.popUpRejectionReasons = response.result;
    }
    public handleItemChange() {
        if (this.selectedRejectReason.id == '0') {
            this.isItOtherReasonPorp = true;
            this.rejectResone = '';
        }
        else {
            this.isItOtherReasonPorp = false;
            this.rejectResone = this.selectedRejectReason.rejectionText
        }
    }
    public get isItOtherReason(): boolean {
        return this.isItOtherReasonPorp;
    }
}
