import Progress from 'easy-circular-progress';
import { Inject } from 'vue-di-container';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import LoaderService from '../../Services/LoaderService';
import { Toaster } from '../../Services/toast';
import ValidationService from '../../Services/ValidationService';
import UserModel from '../../shared/userService/Model/UserModel';
import UserRoleService from '../../shared/userService/UserRoleService';
import UserService from '../../shared/userService/UserService';
import { RejectionReasonModel } from '../RejectionReason/RejectionReasonModel';
import { ReportModel, DropdownModel } from '../Report/ReportModel';
import ReportService from '../Report/ReportService';
import WithRender from './Dashboard.html';
import { DashboardBoxesModel } from './DashboardBoxesModel';
import DashboardService from './DashboardService';
import { FilterModel } from './FilterModel';
import { ReportOptionsModel } from './ReportOptionsModel';
import { RequestsOptionsModel } from './RequestsOptionsModel ';
import { SenderRequestModel } from './SenderRequestModel';
import { SenderType } from '../../shared/Enums/SenderType';
import '@mdi/font/css/materialdesignicons.css';
import ProviderStatusService from '../../shared/ProviderStatusService/ProviderStatusService';


@WithRender
@Component({
    components: {
        Progress,
    },
    diProvide: [
        DashboardService,
        UserService,
        ReportService,
        UserRoleService,
        ProviderStatusService
    ],
})
export default class Dashboard extends Vue {

    @Inject(UserService) public userService!: UserService;
    @Inject(DashboardService) public dashboardService!: DashboardService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(ReportService) public reportService!: ReportService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Inject(ProviderStatusService) public providerStatusService!: ProviderStatusService;


    @Prop({ type: Boolean, default: false })
    public providerLocked: boolean = true;
    public providerDissconected: boolean = true;
    public providerExpired: boolean = true;
    public layoutSup: boolean | any;
    public requestStatus: string = '';
    public clientToFavor: string = '';
    public clientToUnfavor: string = '';
    public senderStatus: string = '';
    public Deactivateids: string[] = [];
    public deactivateDialog: boolean = false;
    public senderStatusModel: any = {
        senderName: '',
        clientName: '',
        crNumber: '',
        enterpriseUnifiedNumber: '',
        customerType: '',
        senderType: '',
        senderStatus: '',
        hrsdLicense: '',
        isAlreadyExist: '',
    };
    public requestsDateExpiryOptions: any =
        [
            { "id": 1, "name": "90 Days" },
            { "id": 2, "name": "30 Days" },
            { "id": 3, "name": "7 Days" },
        ];
    public Deleteids: string[] = [];
    public deleteDialog: boolean = false;
    public isExportExcelForProviderEnabled: boolean = false;
    public approveids: string[] = [];
    public approveDialog: boolean = false;
    public finalApproveDialog: boolean = false;
    public resendids: string[] = [];
    public resendDialog: boolean = false;
    public favoriteDialog: boolean = false;
    public unfavoriteDialog: boolean = false;
    public resendComment: string = '';
    public approveComment: string = '';
    public deleteComment: string = '';
    public checkSenderDialog: boolean = false;
    public notFounDialog: boolean = false;
    public requestsFilterExpiryOption: number = 0;

    public filterModel: FilterModel = {
        senderName: '',
        clientName: '',
        createdDate: '',
        providerName: '',
        requestId: '',
        status: 0,
    };
    public reportModel: ReportModel = {
        requestId: '',
        tenantName: '',
        senderName: '',
        approveDateFrom: '',
        approveDateTo: '',
        createdDateFrom: '',
        createdDateTo: '',
        customerType: 0,
        senderType: 0,
        providerId: '',
        clientName: '',
        comment: '',
        representativeName: '',
        representativeMobile: '',
        requestStatus: 0,
        senderStatus: 0,
        numberOfActions: 0,
        paymentDate: '',
        expiryDate: '',
        filterOption: 0,
        filterExpiryOption: 0
    };
    public statusOptions: any[] = [
        {
            id: '1',
            name: this.$t('dashboardPage.pendingFor').toString()
                + ' ' + this.$t('dashboardPage.approval').toString(),
        },
        {
            id: '2',
            name: this.$t('dashboardPage.pendingFor').toString()
                + ' ' + this.$t('dashboardPage.payment').toString(),
        },
        {
            id: '3',
            name: this.$t('dashboardPage.rejected').toString()
                + ' ' + this.$t('dashboardPage.requests').toString(),
        },
        {
            id: '4',
            name: this.$t('dashboardPage.inactive').toString()
                + ' ' + this.$t('dashboardPage.requests').toString(),
        },
        {
            id: '5',
            name: this.$t('dashboardPage.active').toString()
                + ' ' + this.$t('dashboardPage.requests').toString(),
        },
        {
            id: '7',
            name: this.$t('dashboardPage.partially').toString()
                + ' ' + this.$t('dashboardPage.activated').toString(),
        },
        {
            id: '8',
            name: this.$t('dashboardPage.pendingFor').toString()
                + ' ' + this.$t('dashboardPage.verify').toString(),
        },
    ];
    public user: UserModel = {
        userId: '',
        username: '',
        details: {
            Email: '',
            MobilePhone: '',
            Operator: '',
            Provider: '',
            OperatorId: '',
            ProviderId: '',
            TenantId: '',
            Role: '',
            ChangePasswordRequired: '',
        },
    };
    public senderName: string = '';
    public autoInterval: any;
    public userRole: number = 0;
    public scrollDiv: any;
    public nextPage: string = '';
    public scrollTop: number = 0;
    public selected: SenderRequestModel[] = [];
    public selectedIds: string[] = [];
    public selectedSenderIds: string[] = [];
    public ids: string[] = [];
    public senders: SenderRequestModel[] = [];
    public sendersCount: number = 0;
    public dashboardBoxes: DashboardBoxesModel = {} as DashboardBoxesModel;
    public requestsOptions: RequestsOptionsModel = {} as RequestsOptionsModel;
    public reportOptionsModel: ReportOptionsModel = {} as ReportOptionsModel;
    public User: any;
    public isSorted: boolean = false;
    public rejectResone: string = '';
    public selectedRejectReason: RejectionReasonModel = {} as RejectionReasonModel;
    public rejectdialog: boolean = false;
    public anySelected: boolean = false;
    public pagination: any = {};
    public tenantId: string = '';
    public senderType: number[] = [];
    public senderTypeList: DropdownModel[] = [
        {
            id: SenderType.Advertisement,
            name: this.$t('shared.advertisement').toString(),
        },
        {
            id: SenderType.Service,
            name: this.$t('shared.service').toString(),
        },
        {
            id: SenderType.Awareness,
            name: this.$t('shared.awareness').toString(),
        },
        {
            id: SenderType.Warning,
            name: this.$t('shared.warning').toString(),
        },
    ];
    public headers: any = [
        { text: this.$t('dashboardPage.requestId'), sortable: true, value: 'requestId', align: 'center' },
        { text: this.$t('dashboardPage.senderName'), sortable: true, value: 'senderName', align: 'center' },
        { text: this.$t('dashboardPage.clientName'), sortable: true, value: 'clientName', align: 'center' },
        {
            text: this.$t('dashboardPage.requestDate'), sortable: true,
            value: 'createdDate', align: 'center',
        },
        {
            text: this.$t('dashboardPage.status'), sortable: false, value: 'requestStatus',
            align: 'center', width: '190',
        },
        { text: this.$t('shared.actions'), sortable: false, align: 'center', width: '145' },
    ];


    public popUpRejectionReasons: RejectionReasonModel[] = [];
    public isItOtherReasonPorp: boolean = false;
    public changeSort(column: any) {
        if (this.pagination.sortBy === column) {
            this.pagination.descending = !this.pagination.descending;
        } else {
            this.pagination.sortBy = column;
            this.pagination.descending = false;
        }
        this.isSorted = true;
        this.updatePaginate(this.pagination);
    }
    public async renewRegisterSender() {
        this.$router.push({ name: 'RenewRegisterSender' });
    }
    public checkColor(typeStatus: string) {
        typeStatus = typeStatus.toString();
        switch (typeStatus) {
            case '1': {
                return '#c427ba';
                break;
            }
            case '2': {
                return '#5aaa0b';
                break;
            }
            case '3': {
                return '#C42727';
                break;
            }
            case '4': {
                return '#aaa7a7';
                break;
            }
            case '5': {
                return '#014bb1';
                break;
            }
            case '7': {
                return '#5c9bf2';
                break;
            }
            case '10': {
                return '#9b2a5a';
                break;
            }
            default: {
                return '#F78500';
                break;
            }
        }
    }

    public getSenderType() {
        this.senderType = [];
        const senderBinary = this.senderStatusModel.senderType;
        if ((senderBinary & 1) === 1) {
            this.senderType.push(1);
        }
        if ((senderBinary & 2) === 2) {
            this.senderType.push(2);
        }
        if ((senderBinary & 4) === 4) {
            this.senderType.push(4);
        }
        if ((senderBinary & 8) === 8) {
            this.senderType.push(8);
        }
    }

    public getSenderTypeText(typeId: number) {
        switch (typeId) {
            case SenderType.Advertisement:
                return this.$t('shared.advertisement').toString();
            case SenderType.Service:
                return this.$t('shared.service').toString();
            case SenderType.Awareness:
                return this.$t('shared.awareness').toString();
            case SenderType.Warning:
                return this.$t('shared.warning').toString();
            default:
                return '-';
        }
    }


    public async checkSenderStatus() {
        var response = await this.dashboardService.getSenderStatus(this.senderName);
        if (response.isSuccess) {
            this.senderStatusModel = response.result;
            this.getSenderType();
            this.senderStatusModel.senderStatus = this.getSenderStatusById(this.senderStatusModel.senderStatus);
            this.checkSenderDialog = true;
        } else {
            Toaster.error(response.errorMessage);
        }
    }

    public hideCheckSenderdialog() {
        this.checkSenderDialog = false;
    }


    public getSenderStatusById(typeStatus: number) {
        switch (typeStatus) {
            case 2: {
                return this.$t('shared.approved').toString();
                break;
            }
            case 4: {
                return this.$t('shared.deactivated').toString();
                break;
            }
            case 5: {
                return this.$t('shared.expired').toString();
                break;
            }
            case 7: {
                return this.$t('shared.paid').toString();
                break;
            }
            case 9: {
                return this.$t('shared.pendingForVerify').toString();
                break;
            }
            case 10: {
                return this.$t('shared.pendingForReview').toString();
                break;
            }
        }
    }

    public GetSenderStatus(typeStatus: string) {
        typeStatus = typeStatus.toString();
        switch (typeStatus) {
            case '1': {
                return 'Pending Approval';
                break;
            }
            case '2': {
                return 'Approved';
                break;
            }
            case '3': {
                return 'Rejected';
                break;
            }
            case '4': {
                return 'Paid';
                break;
            }
            case '5': {
                return 'Expired';
                break;
            }
            case '6': {
                return 'Deleted';
                break;
            }
            case '7': {
                return 'Partially Activated';
                break;
            }
            case '9': {
                return 'Pending For Verify';
                break;
            }
            case '10': {
                return 'Pending For Review';
                break;
            }
            default: {
                return 'Deactivated';
                break;
            }
        }
    }


    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.User = await this.userService.getUser();
        this.userRole = this.userRoleService.GetUserRole(this.User);
        if (this.userRole === 2 || this.userRole === 5) {
            this.isProviderLocked();
            this.isProviderExpired();
            this.isProviderDissconected();
        }
        this.getRequestsOptions();
        this.getReportOptions();
        this.dashboardBoxes = await this.dashboardService.GetDashboardBoxes();
        this.tenantId = this.User.details.TenantId;
        this.getRejectionReasons();
        if (this.userRole != 2 && this.userRole != 5) {
            this.getHeaders();
        }
        this.search();
    }
    public async getRequestsOptions() {
        const response = await this.dashboardService.getRequestsOptions();
        this.requestsOptions.isChangeSenderTypeEnabled = response.result.isChangeSenderTypeEnabled;
        this.requestsOptions.isNewRequestEnabled = response.result.isNewRequestEnabled;
        this.requestsOptions.isUpdateRequstDataEnabled = response.result.isUpdateRequstDataEnabled;
        this.requestsOptions.isRenewRequstEnabled = response.result.isRenewRequstEnabled;
    }
    public async getReportOptions() {
        const response = await this.dashboardService.getReportOptions();
        this.isExportExcelForProviderEnabled = response.result.isExportExcelForProviderEnabled;
    }

    public async getHeaders() {
        const providerColumn = {
            text: this.$t('dashboardPage.providerName'), sortable: true,
            value: 'providerName', align: 'center',
        };
        this.headers.splice(3, 0, providerColumn);
    }
    public async isProviderLocked() {
        const response = await this.dashboardService.isProviderLocked();
        this.providerLocked = response.result;
    }
    public async isProviderDissconected() {
        const response = await this.providerStatusService.isProviderDissconected();
        this.providerDissconected = response.result;
    }
    public async isProviderExpired() {
        const response = await this.dashboardService.isProviderExpired();
        this.providerExpired = response.result;
    }
    public async search() {
        let model;
        if (localStorage.storSettings) {
            const storSettings = JSON.parse(localStorage.storSettings);
            this.pagination = storSettings.pagination;
            model = storSettings.filter;
            this.filterModel.senderName = storSettings.filter.senderName;
            this.filterModel.requestId = storSettings.filter.requestId;
            this.filterModel.clientName = storSettings.filter.clientName;
            this.filterModel.providerName = storSettings.filter.providerName;
            this.filterModel.createdDate = storSettings.filter.createdDate;
            this.requestStatus = storSettings.filter.requestStatus;
            this.senderStatus = storSettings.filter.senderStatus;
        } else {
            model = {
                senderName: this.filterModel.senderName,
                requestId: this.filterModel.requestId,
                clientName: this.filterModel.clientName,
                providerName: this.filterModel.providerName,
                createdDate: this.filterModel.createdDate,
                requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
                senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
                requestsFilterExpiryOption: this.requestsFilterExpiryOption,
                sortDirection: false,
                sortBy: '',
                pageIndex: '0',
                pagesize: '10',
            };
        }
        localStorage.removeItem('storSettings');
        const response = await this.dashboardService.Get(model);
        if (response !== false) {
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            }
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }

    public isEqual(value1: string | null, value2: string) {
        return (value1 === '' || value1 === null || value2.includes(value1));
    }

    public async filter(requestStatus: string, senderStatus: string) {
        if (this.requestStatus === requestStatus &&
            this.senderStatus === senderStatus) {
            this.requestStatus = '0';
            this.senderStatus = '0';
            requestStatus = '0';
            senderStatus = '0';
        } else {
            this.requestStatus = requestStatus;
            this.senderStatus = senderStatus;
        }
        const model = {
            senderName: this.filterModel.senderName,
            requestId: this.filterModel.requestId,
            clientName: this.filterModel.clientName,
            providerName: this.filterModel.providerName,
            createdDate: this.filterModel.createdDate,
            requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
            senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
            sortDirection: this.pagination.descending,
            requestsFilterExpiryOption: this.requestsFilterExpiryOption,
            sortBy: this.pagination.sortBy,
            pageIndex: '0',
            pagesize: '10',
        };
        const response = await this.dashboardService.Get(model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }

    public selectAll() {
        if (this.selected.length) {
            this.selected = [];
            this.selectedIds = [];
            this.selectedSenderIds = [];
        } else {
            this.selected = this.senders.slice();
            this.selectedIds = this.senders.map((x) => x.id);
            this.selectedSenderIds = this.senders.map((x) => x.senderId);
        }
    }
    public select(event: any, id: string) {
        if (!event) {
            const index = this.selectedIds.indexOf(id);
            this.selectedIds.splice(index, 1);
            this.selectedSenderIds.splice(index, 1);
        } else {
            this.selectedIds.push(id);
            let selectedObj = this.selected.find(s => s.id == id);
            if (selectedObj !== undefined) {
                this.selectedSenderIds.push(selectedObj.senderId);
            }
        }
    }
    public getRate(remainingDays: string, totalDays: string) {
        return (parseInt(remainingDays, 10) / parseInt(totalDays, 10)) * 100;
    }
    public showapproveDialog(id: string) {
        if (id !== '') {
            this.selectedIds.push(id);
        }
        this.approveDialog = true;
    }
    public showFinalapproveDialog(id: string) {
        if (id !== '') {
            this.selectedIds.push(id);
        }
        this.finalApproveDialog = true;
    }
    public hideapproveDialog() {
        this.approveComment = '';
        this.approveDialog = false;
    }
    public hideFinalApproveDialog() {
        this.finalApproveDialog = false;
    }
    public async approve(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        } else {
            ids = this.selectedIds;
            if (this.senders.filter((s) => ids.includes(s.id) &&
                (s.requestStatus.toString() != '1' && s.requestStatus.toString() != '3')
            ).length > 0
            ) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderPendingApproval').toString());
                return;
            }
            if (this.senders.filter((s) => ids.includes(s.id) && s.tenantId !== this.tenantId)
                .length > 0 && this.userRole === 1) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderRequestRelatedToThisTenant').toString());
                return;
            }
        }
        const response = await this.dashboardService.approve(ids, this.approveComment);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
            await this.reload();
            this.ids = [];
            this.selectedIds = [];
            this.selectedSenderIds = [];
        } else {
            Toaster.success(this.$t('dashboardPage.Approvedsuccessfully').toString());
            await this.reload();
            this.ids = [];
            this.selectedIds = [];
            this.selectedSenderIds = [];
        }
        this.hideapproveDialog();
    }
    public async finalApprove(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        } else {
            ids = this.selectedIds;
            if (this.senders.filter((s) => ids.includes(s.id) &&
                (s.requestStatus.toString() == '4' || s.requestStatus.toString() == '5'
                    || s.requestStatus.toString() == '6' || (s.requestStatus.toString() == '3' && this.userRole !== 1 && this.userRole !== 9))
            ).length > 0
            ) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderPendingApproval').toString());
                return;
            }
            if (this.senders.filter((s) => ids.includes(s.id) && s.tenantId !== this.tenantId)
                .length > 0 && this.userRole === 1) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderRequestRelatedToThisTenant').toString());
                return;
            }
        }
        const response = await this.dashboardService.finalApprove(ids, this.approveComment);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
            await this.reload();
            this.ids = [];
            this.selectedIds = [];
        } else {
            Toaster.success(this.$t('shared.finalApprovedsuccessfully').toString());
            await this.dashboardService.sendrApproveEmail(ids);
            await this.reload();
            this.ids = [];
            this.selectedIds = [];
        }
        this.hideFinalApproveDialog();
    }
    public showDeactivateDialog(id: string) {
        this.Deactivateids = [];
        if (id !== '') {
            this.Deactivateids.push(id);
        } else {
            this.Deactivateids = this.selectedIds;
        }
        this.deactivateDialog = true;
    }

    public hideDeactivateDialog() {
        this.deleteComment = '';
        this.deactivateDialog = false;
    }
    public showDeleteDialog(id: string) {
        this.Deleteids = [];
        if (id !== '') {
            this.Deleteids.push(id);
        } else {
            this.Deleteids = this.selectedIds;
        }
        this.deleteDialog = true;
    }
    public hideDeleteDialog() {
        this.deleteDialog = false;
    }
    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }
    public showresendDialog(id: string) {
        if (id !== '') {
            this.selectedIds.push(id);
        } else {
            this.resendids = this.selectedIds;
        }
        this.resendDialog = true;
    }
    public showFavoriteDialog(clientName: string) {
        this.clientToFavor = clientName;
        this.favoriteDialog = true;
    }
    public hideFavoriteDialog() {
        this.favoriteDialog = false;
    }
    public showUnfavoriteDialog(clientName: string) {
        this.clientToUnfavor = clientName;
        this.unfavoriteDialog = true;
    }
    public hideUnfavoriteDialog() {
        this.unfavoriteDialog = false;
    }
    public hideresendDialog() {
        this.resendComment = '';
        this.resendDialog = false;
    }
    public async exportToExcel() {
        this.loaderService.ShowLoader();
        this.reportModel.requestStatus = this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0;
        this.reportModel.senderStatus = this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0;
        this.reportModel.filterOption = this.requestsFilterExpiryOption;
        this.reportModel.filterExpiryOption = this.requestsFilterExpiryOption;
        const response = await this.reportService.GetSenderNameAndBeneficiariesReport
            (this.reportModel, '0', '0', true);
        if (!response.isSuccess) {
            this.loaderService.HideLoader();
            Toaster.error(response.errorMessage);
        } else {
            this.loaderService.HideLoader();
        }
    }
    public async deactivate(id: string) {
        let ids: string[] = [];
        ids = this.Deactivateids;
        if (this.senders.filter((s) => ids.includes(s.id) && !(s.requestStatus.toString() === '4' || s.requestStatus.toString() === '5' || s.requestStatus.toString() === '6')).length > 0) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderActivateOrPartiallyActivated').toString());
            return;
        }
        const response = await this.dashboardService.deactivate(ids);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
            this.deactivateDialog = false;
            await this.reload();
        } else {
            this.deactivateDialog = false;
            this.selectedIds = [];
            this.selectedSenderIds = [];
            Toaster.success(this.$t('dashboardPage.deactivatedsuccessfully').toString());
            await this.reload();
        }
    }
    public async deleteSender() {
        let ids: string[] = [];
        ids = this.Deleteids;
        if (this.senders.filter((s) => ids.includes(s.id) && !(s.requestStatus.toString() === '4'
            || s.requestStatus.toString() === '2')).length > 0) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderApprovedOrPaid').toString());
            return;
        }
        const response = await this.dashboardService.delete(ids[0], this.deleteComment);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
            this.deleteDialog = false;
            await this.reload();
        } else {
            this.deleteDialog = false;
            this.selectedIds = [];
            this.selectedSenderIds = [];
            this.dashboardService.deleteRequestEmail(this.Deleteids);
            Toaster.success(this.$t('shared.deleteSuccessfully').toString());
            await this.reload();
        }
    }
    public async favorClient() {
        const response = await this.dashboardService.FavorClient(this.clientToFavor);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        } else {
            Toaster.success(this.$t('shared.clientFavoriteSuccessfully').toString());
            await this.reload();
        }
        this.hideFavoriteDialog();
    }
    public async unfavorClient() {
        const response = await this.dashboardService.UnfavorClient(this.clientToUnfavor);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        } else {
            Toaster.success(this.$t('shared.clientUnfavoriteSuccessfully').toString());
            await this.reload();
        }
        this.hideUnfavoriteDialog();
    }
    public async resend(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        } else {
            ids = this.selectedIds;
            if (this.senders.filter((s) => ids.includes(s.id) && s.requestStatus.toString() !== '3')
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderRejected').toString());
                return;
            }
        }
        const response = await this.dashboardService.resend(ids, this.resendComment);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        } else {
            await this.dashboardService.resendRequestEmail(ids);
            this.selectedIds = [];
            this.selectedSenderIds = [];
            Toaster.success(this.$t('dashboardPage.resentSuccessfully').toString());
            await this.reload();
        }
        this.hideresendDialog();
    }
    public async showRejectDialog(id: string) {
        this.ids = [];
        if (id !== '') {
            this.ids.push(id);
        } else {
            this.ids = this.selectedIds;
            if (this.senders.filter((s) => this.ids.includes(s.id) &&
                (s.requestStatus.toString() == '4' || s.requestStatus.toString() == '5'
                    || s.requestStatus.toString() == '6' || (s.requestStatus.toString() == '2' && this.userRole !== 1 && this.userRole !== 9))
            ).length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderPendingApproval').toString());
                return;
            }
        }
        this.rejectdialog = true;
    }
    public async hideRejectDialog() {
        this.rejectResone = '';
        this.rejectdialog = false;
    }
    public async reject() {
        if (this.senders.filter((s) => this.ids.includes(s.id) && s.tenantId !== this.tenantId)
            .length > 0 && this.userRole === 1) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderRequestRelatedToThisTenant').toString());
            return;
        }
        if (this.rejectResone !== '') {
            this.rejectdialog = false;
            const response = await this.dashboardService.reject(this.ids, this.rejectResone);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            } else {
                await this.dashboardService.rejectRequestEmail(this.ids);
                this.rejectResone = '';
                this.selectedIds = [];
                this.selectedSenderIds = [];
                this.ids = [];
                Toaster.success(this.$t('dashboardPage.Rejectedsuccessfully').toString());
                await this.reload();
            }
        }
    }
    public PreparePay(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        } else {
            ids = this.selectedIds;
            if (this.senders.filter((s) => ids.includes(s.id) && s.requestStatus.toString() !== '2')
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderPendingPay').toString());
                return;
            }
        }
        const storSettings = {
            pagination: this.pagination,
            filter: {
                senderName: this.filterModel.senderName,
                requestId: this.filterModel.requestId,
                clientName: this.filterModel.clientName,
                providerName: this.filterModel.providerName,
                createdDate: this.filterModel.createdDate,
                requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
                senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
                pageIndex: (this.pagination.page - 1).toString(),
                pagesize: this.pagination.rowsPerPage,
                sortDirection: this.pagination.descending,
                sortBy: this.pagination.sortBy,
            },
        };
        localStorage.storSettings = JSON.stringify(storSettings);
        this.$router.push({ name: 'Payment', params: { ids: ids.join(',') } });
    }
    public Details(id: string) {
        const storSettings = {
            pagination: this.pagination,
            filter: {
                senderName: this.filterModel.senderName,
                requestId: this.filterModel.requestId,
                clientName: this.filterModel.clientName,
                providerName: this.filterModel.providerName,
                createdDate: this.filterModel.createdDate,
                requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
                senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
                pageIndex: (this.pagination.page - 1).toString(),
                pagesize: this.pagination.rowsPerPage,
                sortDirection: this.pagination.descending,
                sortBy: this.pagination.sortBy,
            },
        };
        localStorage.storSettings = JSON.stringify(storSettings);
        this.$router.push({ name: 'SenderInfo', params: { id } });
    }
    public async checkRenewSender(senderId: string) {
        if (this.isAnySelected) {
            let ids: string[] = [];
            if (senderId !== '') {
                ids.push(senderId);
            } else {
                ids = this.selectedSenderIds;
            }
            const response = await this.dashboardService.checkRenewSender(ids);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
                await this.reload();
                this.selectedIds = [];
                this.selectedSenderIds = [];
            } else {
                this.$router.push({ name: 'RenewRegisterSender', params: { ids: ids.join(',') } });
            }
        } else {
            this.$router.push({ name: 'RenewRegisterSender' });
        }
    }

    public ChangeSenderType(id: string) {
        const storSettings = {
            pagination: this.pagination,
            filter: {
                senderName: this.filterModel.senderName,
                requestId: this.filterModel.requestId,
                clientName: this.filterModel.clientName,
                providerName: this.filterModel.providerName,
                createdDate: this.filterModel.createdDate,
                requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
                senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
                pageIndex: (this.pagination.page - 1).toString(),
                pagesize: this.pagination.rowsPerPage,
                sortDirection: this.pagination.descending,
                sortBy: this.pagination.sortBy,
            },
        };
        localStorage.storSettings = JSON.stringify(storSettings);
        this.$router.push({ name: 'ChangeSenderType', params: { id } });
    }
    public ConectivityDetail(senderId: string) {
        this.$router.push({ name: 'SenderConectivityDetail', params: { senderId } });
    }
    public async updatePaginate(val: any) {
        const model = {
            senderName: this.filterModel.senderName,
            requestId: this.filterModel.requestId,
            clientName: this.filterModel.clientName,
            providerName: this.filterModel.providerName,
            createdDate: this.filterModel.createdDate,
            requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
            senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
            requestsFilterExpiryOption: this.requestsFilterExpiryOption,
            pageIndex: (val.page - 1).toString(),
            pagesize: val.rowsPerPage,
            sortDirection: this.pagination.descending,
            sortBy: this.isSorted ? this.pagination.sortBy : '',
        };
        const response = await this.dashboardService.Get(model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }
    public get isAnySelected(): boolean {
        this.anySelected = this.selectedIds.length > 0;
        return this.anySelected;
    }
    public async reload() {
        this.isSorted = false;
        this.requestsFilterExpiryOption = 0;
        const model = {
            senderName: this.filterModel.senderName,
            requestId: this.filterModel.requestId,
            clientName: this.filterModel.clientName,
            providerName: this.filterModel.providerName,
            createdDate: this.filterModel.createdDate,
            requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
            senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
            requestsFilterExpiryOption: 0,
            pageIndex: '0',
            pagesize: '10',
            sortDirection: false,
            sortBy: '',
        };
        const response = await this.dashboardService.Get(model);
        this.dashboardBoxes = await this.dashboardService.GetDashboardBoxes();
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
        this.selectedIds = [];
        this.selectedSenderIds = [];
        this.selected = [];
    }

    @Watch('requestsFilterExpiryOption', { deep: true })
    public onchangeRequestsFilterExpiry() {
        this.search();
    }

    public get rejectReasonIsValid(): boolean {
        return this.rejectResone !== '';
    }
    public async getRejectionReasons() {
        const response = await this.dashboardService.getSenderNameRejectReasons(1);
        this.popUpRejectionReasons = response.result;
        this.popUpRejectionReasons.push({ id: '0', rejectionText: 'other', requestType: 1 })

    }
    public handleItemChange() {
        if (this.selectedRejectReason.id == '0') {
            this.isItOtherReasonPorp = true;
            this.rejectResone = '';
        }
        else {
            this.isItOtherReasonPorp = false;
            this.rejectResone = this.selectedRejectReason.rejectionText
        }
    }
    public get isItOtherReason(): boolean {
        return this.isItOtherReasonPorp;
    }
}
