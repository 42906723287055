<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <label class="header_text mb-3">
                {{$t('shared.payment')}}
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body card_body_layout">
                    <div class="row">
                        <div class="col-md-5 border-right">
                            <label class="card_header_orders">{{$t('shared.orderSummary')}}</label>
                            <ul class="list-group list_orders">
                                <li v-for="sender in sendersPaymentSummery.details" class="list-group-item d-flex justify-content-between align-items-end">
                                    <div class="order_text">
                                        <label class="order_title mb-0"></label>
                                        <h5 class="order_sub_title">{{sender.senderName}}</h5>
                                    </div>
                                    <label class="order_cost">{{sender.amount}} SAR</label>
                                </li>
                            </ul>
                            <div class="d-flex justify-content-between align-items-center">
                                <label class="order_total">{{$t('shared.total')}}</label>
                                <label class="order_total text-right"> {{sendersPaymentSummery.total}} SAR</label>
                            </div>
                        </div>
                        <div class="col-md-7" v-if="showPaymentOptions">
                            <label class="card_header_orders">{{$t('shared.PaymentOptions')}}</label>
                            <div calss="row">
                                <input type="radio" :disabled="!sendersPaymentSummery.isCreditCardEnabled" id="CreditCard" value="1" v-model="paymentOptions" @change="hideOtherPaymentOptions()" />
                                <label for="CreditCard" style="padding: 10px;">{{$t('shared.creditCardVisaMastercard')}}</label>
                            </div>
                            <div calss="row">
                                <input type="radio" :disabled="!sendersPaymentSummery.isCreditCardEnabled" id="DebitCardMada" value="2" v-model="paymentOptions" @change="hideOtherPaymentOptions()" />
                                <label for="DebitCardMada" style="padding: 10px;">{{$t('shared.debitCardMada')}}</label>
                            </div>
                            <div calss="row">
                                <input type="radio" :disabled="!isCreditLimitOptionValid" id="CreditLimit" value="3" v-model="paymentOptions" @change="hideOtherPaymentOptions()" />
                                <label for="CreditLimit" style="padding: 10px;">{{$t('shared.CreditLimit')}}</label>
                            </div>
                            <div calss="row" v-if="sendersPaymentSummery.isPublicPaymentLinkEnabled">
                                <div calss="row">
                                    <input type="radio" :disabled="!sendersPaymentSummery.isCreditCardEnabled" id="CreatePaymentLink" value="4" v-model="paymentOptions" @change="hideOtherPaymentOptions()" />
                                    <label for="CreatePaymentLink" style="padding: 10px;">{{$t('shared.createPaymentLinkVisaMastercard')}}</label>
                                </div>
                                <div calss="row">
                                    <input type="radio" :disabled="!sendersPaymentSummery.isCreditCardEnabled" id="CreatePaymentLinkMada" value="5" v-model="paymentOptions" @change="hideOtherPaymentOptions()" />
                                    <label for="CreatePaymentLinkMada" style="padding: 10px;">{{$t('shared.createPaymentLinkMada')}}</label>
                                </div>
                                <div v-if="isCreatePaymentLinkOption == true && showPaymentLink == true">
                                    <div class="col-md-12">
                                        <div class="input-group mb-8">
                                            <input class="form-control" v-model="urlString" type="text" @click="copyURL" />
                                            <div class="input-group-append">
                                                <span class="input-group-text right gray">
                                                    <i class="fas fa-copy"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto pt-5">
                                <button class="btn btn-success px-5 py-2" @click="GotoPaymentOption" :disabled="!isOptionChooseValid">{{$t('shared.Go')}}</button>
                            </div>
                        </div>
                        <div id="hyperPaycontainer" v-if="!showPaymentOptions && !isMada" class="col-md-7">
                            <v-form :action="shopperResultUrl"
                                    name="hyperPayForm"
                                    class="paymentWidgets"
                                    data-brands="VISA MASTER"></v-form>
                        </div>
                        <div id="hyperPaycontainer" v-if="!showPaymentOptions && isMada" class="col-md-7">
                            <v-form :action="shopperResultUrl"
                                    name="hyperPayForm"
                                    class="paymentWidgets"
                                    data-brands="MADA"></v-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-xs-center">
            <v-dialog v-model="fellproviderinfodialog" persistent width="900">
                <v-card>
                    <v-card-title class="headline grey lighten-2" primary-title>
                        {{$t('shared.fellInfo')}}
                        <div class="close__dialog">
                            <i class="fas fa-times" @click="redirectToDashboard"></i>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <div class="row">
                            <v-card-text class="col-md-4">
                                <div class="form-group">
                                    <label>{{$t('shared.vatNumber')}}</label>
                                    <input class="form-control" v-model="providerInvoiceInfo.vatNumber" type="text" />
                                </div>
                            </v-card-text>
                            <v-card-text class="col-md-4">
                                <div class="form-group">
                                    <label>{{$t('shared.country')}}</label>
                                    <input class="form-control" v-model="providerInvoiceInfo.country" type="text" />
                                </div>
                            </v-card-text>
                            <v-card-text class="col-md-4">
                                <div class="form-group">
                                    <label>{{$t('shared.city')}}</label>
                                    <input class="form-control" v-model="providerInvoiceInfo.city" type="text" />
                                </div>
                            </v-card-text>
                        </div>
                        <div class="row">
                            <v-card-text class="col-md-4">
                                <div class="form-group">
                                    <label>{{$t('shared.district')}}</label>
                                    <input class="form-control" v-model="providerInvoiceInfo.district" type="text" />
                                </div>
                            </v-card-text>
                            <v-card-text class="col-md-4">
                                <div class="form-group">
                                    <label>{{$t('shared.streetName')}}</label>
                                    <input class="form-control" v-model="providerInvoiceInfo.streetName" type="text" />
                                </div>
                            </v-card-text>
                            <v-card-text class="col-md-4">
                                <div class="form-group">
                                    <label>{{$t('shared.buildingNumber')}}</label>
                                    <input class="form-control" v-model="providerInvoiceInfo.buildingNumber" type="text" />
                                </div>
                            </v-card-text>
                        </div>
                        <div class="row">
                            <v-card-text class="col-md-4">
                                <div class="form-group">
                                    <label>{{$t('shared.postalCode')}}</label>
                                    <input class="form-control" v-model="providerInvoiceInfo.postalCode" type="text" />
                                </div>
                            </v-card-text>
                            <v-card-text class="col-md-4">
                                <div class="form-group">
                                    <label>{{$t('shared.additionalNumber')}}</label>
                                    <input class="form-control" v-model="providerInvoiceInfo.additionalNumber" type="text" />
                                </div>
                            </v-card-text>
                            <v-card-text class="col-md-4">
                                <div class="form-group">
                                    <label>{{$t('shared.otherIdentifier')}}</label>
                                    <input class="form-control" v-model="providerInvoiceInfo.otherIdentifier" type="text" />
                                </div>
                            </v-card-text>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <div class="form-row justify-content-end align-items-center">
                            <div class="col-auto mx-3">
                                <a class="link_danger" @click="redirectToDashboard">
                                    {{$t('shared.cancel')}}
                                </a>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-danger px-5 py-2" :disabled="!vatNumberIsValid" @click="updateProvider">{{$t('shared.save')}}</button>
                            </div>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</div>